/* The pop-up container */
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  animation: fade-in 0.5s forwards; /* Background fade-in animation */
}

/* Background fade-in animation */
@keyframes fade-in {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

/* Pop-up content */
.popup-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 300px; /* Increase the max width to allow more space */
  max-height: 300px; /* Increase the max height */
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: scale(0.8) translateY(-20px);
  animation: popup-content-animation 0.5s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.popup-content img {
  max-width: 60%;
  max-height: 60%;
  object-fit: contain; /* Ensures the image scales correctly within the container */
  display: block;
  margin: 0 auto; /* Center the image */
}

/* Pop-up content animation */
@keyframes popup-content-animation {
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* Close button */
.close-btn {
  color: #000;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.close-btn:hover,
.close-btn:focus {
  color: #ff0000;
  text-decoration: none;
}

/* Popup text */
.popup-text {
  color: #000;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgb(36, 61, 203); /* Yellow shadow */
  font-weight: bold;
  margin-top: 20px;
}

/* Adjustments for placement */
@media (min-width: 480px) {
  .popup-content {
    left: 20%;
    
  }
}